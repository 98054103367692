// MUIS
import Menu from '@mui/material/Menu'
import { styled } from '@mui/material/styles'

const CustomMenu = styled(({ className, ...props }) => (
  <Menu
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 2,
  },
}))

export default CustomMenu