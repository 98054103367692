import React from 'react'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconRemove from '@mui/icons-material/Remove'

// STYLES
import useStyles from './panelZoomUseStyles'

const PanelZoom = (props) => {
  const { 
    zoom, 
    setZoom, 
  } = props

  const classes = useStyles()

  const handleZoomChange = (inputState) => {
    if(inputState === 'add' && zoom < 20) setZoom(current => current + 1)
    else if(inputState === 'minus' && zoom > 2) setZoom(current => current - 1)
  }

  return (
    <>
      {/* ZOOM BUTTON GROUP PANEL */}
      <ButtonGroup 
        className={classes.panelContainer}
        variant='contained'
        orientation='vertical'
      >
        {/* ZOOM IN BUTTON */}
        <CustomTooltipBlack
          title='Zoom In'
          placement='left'
        >
          <Button 
            className={classes.buttonGroupItem}
            variant='text'
            onClick={() => handleZoomChange('add')}
          >
            <IconAdd className={classes.icon}/>
          </Button>
        </CustomTooltipBlack>

        {/* ZOOM OUT BUTTON */}
        <CustomTooltipBlack
          title='Zoom Out'
          placement='left'
        >
          <Button 
            className={classes.buttonGroupItem}
            variant='text'
            onClick={() => handleZoomChange('minus')}
          >
            <IconRemove className={classes.icon}/>
          </Button>
        </CustomTooltipBlack>
      </ButtonGroup>
    </>
  )
}

export default PanelZoom